// extracted by mini-css-extract-plugin
export var briefEntry = "AccountPopup-module--briefEntry--8f501";
export var copyBtn = "AccountPopup-module--copyBtn--95668";
export var fadeIn = "AccountPopup-module--fadeIn--ed955";
export var fadeInFromTop = "AccountPopup-module--fadeInFromTop--6f0fb";
export var fallingArrow = "AccountPopup-module--fallingArrow--906cf";
export var information = "AccountPopup-module--information--90b6e";
export var information__example = "AccountPopup-module--information__example--33622";
export var information__heading = "AccountPopup-module--information__heading--ec5c1";
export var information__info = "AccountPopup-module--information__info--be713";
export var popup = "AccountPopup-module--popup--2258e";
export var popup__button = "AccountPopup-module--popup__button--ec4e2";
export var popup__container = "AccountPopup-module--popup__container--6a6fe";
export var popup__text = "AccountPopup-module--popup__text--ef4a7";
export var slideInTop = "AccountPopup-module--slide-in-top--fe203";
export var wrapper = "AccountPopup-module--wrapper--0e65e";