exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-atrakcje-index-tsx": () => import("./../../../src/pages/atrakcje/index.tsx" /* webpackChunkName: "component---src-pages-atrakcje-index-tsx" */),
  "component---src-pages-galeria-index-tsx": () => import("./../../../src/pages/galeria/index.tsx" /* webpackChunkName: "component---src-pages-galeria-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-kontakt-index-tsx": () => import("./../../../src/pages/kontakt/index.tsx" /* webpackChunkName: "component---src-pages-kontakt-index-tsx" */),
  "component---src-pages-polityka-prywatnosci-index-tsx": () => import("./../../../src/pages/polityka-prywatnosci/index.tsx" /* webpackChunkName: "component---src-pages-polityka-prywatnosci-index-tsx" */),
  "component---src-pages-regulamin-index-tsx": () => import("./../../../src/pages/regulamin/index.tsx" /* webpackChunkName: "component---src-pages-regulamin-index-tsx" */)
}

